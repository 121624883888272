import Plausible, { type EventOptions, type PlausibleOptions } from "plausible-tracker";

const plausible = Plausible({
	domain: "sichermarti.ch",
});

export function trackPageView() {
	try {
		plausible.trackPageview();
	} catch {}
}

export function trackEvent(eventName: string, options?: EventOptions, eventData?: PlausibleOptions) {
	try {
		plausible.trackEvent(eventName, options, eventData);
	} catch {}
}
