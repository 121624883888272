import "../Styles/index.scss";

import anime, { type AnimeInstance } from "animejs";
import Rellax from "rellax";
import rafScroll from "@wagich/raf-scroll";
import tippy from "tippy.js";
import Flickity from "flickity";
import debounce from "lodash/debounce";
import svelteRetag from "svelte-retag";

import { trackEvent, trackPageView } from "./analytics";
import ContactForm from "./contact-form.svelte";
import AnalysisForm from "./analysis-form.svelte";
import AnalysisCta from "./analysis-cta.svelte";

trackPageView();

const Colors = {
	primary: "#493fb0",
	white: "#ffffff",
	text: "#4a4a4a",
};
const isIE = /Trident\/|MSIE/.test(window.navigator.userAgent);

let videoObserver = new IntersectionObserver((entries, observer) => {
	if (entries[0].isIntersecting) {
		let target = entries[0].target;
		if (target.tagName === "VIDEO" && !target.hasAttribute("autoplay")) {
			(target as HTMLVideoElement).play();
		}
	}
});
for (let element of document.querySelectorAll("video")) {
	videoObserver.observe(element);
}

let header = document.getElementById("header");
let mainLogo = document.getElementById("mainLogo");
if (header != null && mainLogo != null) {
	let animation = anime({
		targets: mainLogo,
		scale: 0.75,
		duration: 500,
		autoplay: false,
		easing: "easeInOutSine",
	});
	animation.complete = animation.reverse;

	let observer = new IntersectionObserver((entries, observer) => {
		animation.play();
	}, {
		threshold: 0.7
	});
	// observe only after first scroll event, because the observer fires on init
	rafScroll.addOnce(() => observer.observe(header!));
}

function getElementScrollPercentage(element: HTMLElement) {
	let rect = element.getBoundingClientRect();
	let rawValue = (1 - (rect.top / window.innerHeight)) / 2;
	return Math.max(0, Math.min(1, rawValue));
}

const overviewSection = document.getElementById("overviewSection");
const overviewSectionBackground = document.getElementById("overviewSectionBackground");
const overviewSectionClaim = document.getElementById("overviewSectionClaim");
const overviewSectionText = document.getElementById("overviewSectionText");
const overviewSectionTitle = document.getElementById("overviewSectionTitle");
if (overviewSection != null
	&& overviewSectionBackground != null
	&& overviewSectionClaim != null) {
	anime.set(overviewSectionBackground, {
		skewX: -10,
		display: "block"
	});
	anime.set(overviewSectionClaim, {
		color: Colors.white
	});
	anime.set(overviewSectionTitle, {
		color: Colors.white
	});
	anime.set(overviewSectionText, {
		color: Colors.white
	});
	let timeline = anime
		.timeline({
			autoplay: false,
			duration: 1000,
			easing: "easeOutQuart"
		})
		.add({
			targets: overviewSectionBackground,
			//translateX: "-50vw",
			translateX: Math.max(-window.innerWidth + (window.innerWidth - overviewSectionBackground.getBoundingClientRect().right), -(window.innerWidth / 2) - 672 + (window.innerWidth - overviewSectionBackground.getBoundingClientRect().right)),
			skewX: -10,
		})
		.add({
			targets: overviewSectionTitle,
			color: Colors.text
		}, 0)
		.add({
			targets: overviewSectionClaim,
			color: Colors.primary
		}, 0)
		.add({
			targets: overviewSectionText,
			color: Colors.text
		}, 0);

	rafScroll.add(e => timeline.seek((getElementScrollPercentage(overviewSection) - 0.04) * 1300));
}

const customerEntrypointBackground = document.getElementById("customerEntrypointBackground");
if (customerEntrypointBackground != null) {
	let animation = anime({
		targets: customerEntrypointBackground,
		translateX: "-10%",
		duration: 1000,
		autoplay: false,
		easing: "linear",
	});
	rafScroll.add(e => animation.seek(getElementScrollPercentage(customerEntrypointBackground) * 1000));
}
const businessEntrypointBackground = document.getElementById("businessEntrypointBackground");
if (businessEntrypointBackground != null) {
	let animation = anime({
		targets: businessEntrypointBackground,
		translateX: "10%",
		duration: 1000,
		autoplay: false,
		easing: "linear",
	});
	rafScroll.add(e => animation.seek(getElementScrollPercentage(businessEntrypointBackground) * 1000));
}

if (!isIE) {
	const imageHeaderTitle = document.getElementById("imageHeaderTitle");
	if (imageHeaderTitle != null) {
		let animation = runHeaderTextAnimation(imageHeaderTitle);
		window.addEventListener("resize", debounce(() => {
			animation?.seek(animation?.duration);
			animation = runHeaderTextAnimation(imageHeaderTitle);
		}, 500));
	}
}

function runHeaderTextAnimation(container: Element) {
	let keepTagNames = ["BR"];
	let descendants = Array.from(container.childNodes)
		.flatMap(n => {
			if (n.nodeType === Node.ELEMENT_NODE && window.getComputedStyle(n as Element).display === "none") {
				return [];
			} else {
				return [n, ...Array.from(n.childNodes)]
			}
		})
		.map(n => n.cloneNode(true));

	let clone = container.cloneNode(false) as Element;
	clone.appendChild(document.createElement("span"));
	for (let node of descendants) {
		if (node.nodeType === Node.ELEMENT_NODE) {
			let element = node as HTMLElement;
			if (keepTagNames.includes(element.tagName)) {
				let wrapper = document.createElement("span");
				wrapper.className = element.className;
				element.className = "";
				wrapper.classList.add("letter");
				wrapper.appendChild(node);
				clone.appendChild(wrapper);
			}
		}
		if (node.nodeType === Node.TEXT_NODE) {
			let parent = node.parentElement;
			let parentClasses = parent?.classList ?? "";
			let dummy = document.createElement("div");
			dummy.innerHTML = node.textContent!.replace(/(\S)/g, `<span class="letter ${parentClasses}">$&</span>`);
			clone.lastChild!.after(...dummy.childNodes);
		}
	}

	container.classList.add("is-hidden");
	container.after(clone);
	return anime({
		targets: clone.children,
		loop: false,
		opacity: [0, 1],
		easing: "easeOutExpo",
		duration: 600,
		offset: "-=775",
		delay: (el, i) => 50 * (i + 1),
		complete: () => {
			container.classList.remove("is-hidden");
			clone.remove();
		}
	});
}

const navToggle = document.getElementById("navToggle");
const navContainer = document.getElementById("navContainer");
const navOverlay = document.getElementById("navOverlay");
const analysisCtaMessage = document.getElementById("analysisCtaMessage");
if (navToggle != null && navContainer != null && navOverlay != null) {
	navToggle.addEventListener("click", e => {
		navContainer.classList.toggle("is-active");
		navOverlay.classList.toggle("is-active");
		analysisCtaMessage?.classList.toggle("is-hidden");
	});
}

const rellax = new Rellax(".rellax");
tippy("[data-tippy-content]");

const graphic = document.querySelector<HTMLElement>(".graphic");
if (graphic != null) {
	let sliderElement = graphic.querySelector(".graphic-slider")!;
	let slider = new Flickity(sliderElement, {
		pageDots: false,
		wrapAround: true,
	});

	tippy(".hotspot", {
		touch: false,
		theme: "light",
		maxWidth: "30em",
		content: hotspot => {
			let index = parseInt(hotspot.getAttribute("data-index") ?? "0", 10);
			let cell = slider.cells[index] as unknown as { element: HTMLElement }; // typedefs are wrong...

			let itemWrapper = document.createElement("div");
			itemWrapper.classList.add("item");
			itemWrapper.innerHTML = cell.element.innerHTML;

			let wrapper = document.createElement("div");
			wrapper.classList.add("graphic-slider");
			wrapper.appendChild(itemWrapper);

			return wrapper;
		}
	});

	slider.on("staticClick", (event, pointer, cellElement, cellIndex) => {
		let removeSelection = function (e: Event) {
			let maybeClickedCell = (e.target as HTMLElement).closest(".item");
			let maybeClickedHotspot = (e.target as HTMLElement).closest<HTMLElement>(".hotspot");
			// only if we clicked outside the active cell
			if (maybeClickedCell != cellElement/* && !maybeClickedHotspot?.classList.contains("is-active")*/) {
				document.removeEventListener("click", removeSelection);

				hotspot?.classList.remove("is-active", "animate__animated", "animate__flash");
				cellElement?.classList.remove("is-active");

				if (graphic.querySelector(".is-active") == null) {
					graphic.classList.remove("has-active");
				}
			}
		}

		let hotspot = graphic.querySelector<HTMLElement>(`.hotspot[data-index='${cellIndex}']`);

		if (typeof cellIndex == "number") {
			slider.select(cellIndex);
		}
		cellElement?.classList.add("is-active");
		hotspot?.classList.add("is-active", "animate__animated", "animate__flash");
		graphic.classList.add("has-active");

		window.setTimeout(() => {
			document.addEventListener("click", removeSelection);
		}, 0);
	});

	graphic.addEventListener("click", e => {
		let hotspot = (e.target as HTMLElement).closest<HTMLElement>(".hotspot");
		if (hotspot != null) {
			let removeSelection = function (e: Event) {
				let maybeClickedCell = (e.target as HTMLElement).closest(".item");
				let maybeClickedHotspot = (e.target as HTMLElement).closest<HTMLElement>(".hotspot");
				// only if we clicked outside the active hotspot
				if (maybeClickedHotspot != hotspot/* && !maybeClickedCell?.classList.contains("is-active")*/) {
					document.removeEventListener("click", removeSelection);

					hotspot?.classList.remove("is-active", "animate__animated", "animate__flash");
					selectedSliderElement.classList.remove("is-active");

					if (graphic.querySelector(".is-active") == null) {
						graphic.classList.remove("has-active");
					}
				}
			}

			let index = parseInt(hotspot.getAttribute("data-index") ?? "0", 10);
			slider.select(index);

			let selectedSliderElement = slider.selectedElement;
			selectedSliderElement.classList.add("is-active");
			hotspot?.classList.add("is-active");
			graphic.classList.add("has-active");

			window.setTimeout(() => {
				document.addEventListener("click", removeSelection);
			}, 0);

			return;
		}

		let item = (e.target as HTMLElement).closest(".item");
		if (item != null) {
			e.preventDefault();
			return;
		}
	});
}

let threesSection = document.getElementById("threesSection");
let threesSectionBackground = document.getElementById("threesSectionBackground");
if (threesSection != null && threesSectionBackground != null) {
	anime.set(threesSectionBackground, { skewX: -10 });
	let animation = anime({
		autoplay: false,
		duration: 1000,
		easing: "linear",
		targets: threesSectionBackground,
		translateX: "-36%",
		skewX: -10,
	});

	rafScroll.add(e => animation.seek(getElementScrollPercentage(threesSection!) * 1000));
}

let teamImageAnimation: AnimeInstance | null;
const teamImage = document.getElementById("teamImage");
if (teamImage != null) {
	let mq = window.matchMedia("(max-aspect-ratio: 1306/805)");
	if (mq.matches) {
		runTeamImageAnimation(teamImage);
	}
	mq.addEventListener("change", e => {
		if (e.matches) {
			teamImageAnimation?.pause();
			runTeamImageAnimation(teamImage);
		} else {
			teamImageAnimation?.pause();
			anime.set(teamImage, { translateX: 0 });
		}
	});
}

function runTeamImageAnimation(image: HTMLElement) {
	anime.set(image, { translateX: 0 });
	window.setTimeout(() => {
		teamImageAnimation = anime({
			autoplay: true,
			duration: 9000,
			easing: "easeInOutSine",
			targets: image,
			direction: "alternate",
			loop: 2,
			translateX: -(image.offsetWidth - window.innerWidth)
		});
	}, 0);
}

document.addEventListener("click", e => {
	let action = (e.target as HTMLElement).getAttribute("data-js-action");
	switch (action) {
		case "openContactForm":
			document.dispatchEvent(new CustomEvent("openContactForm"));
			break;

		case "openAnalysisForm":
			document.dispatchEvent(new CustomEvent("openAnalysisForm"));
			break;
	}
});

svelteRetag({
	component: ContactForm,
	tagname: "contact-form",
});

svelteRetag({
	component: AnalysisForm,
	tagname: "analysis-form",
});

svelteRetag({
	component: AnalysisCta,
	tagname: "analysis-cta",
});
