<script lang="ts">
    import { onMount } from "svelte";
	import ky from "ky";
	import { create, enforce, test, group, skip, only } from "vest";
	import "vest/enforce/email";
	
    import { FormState } from "./utils";
	import { trackEvent } from "./analytics";
	import { ValidatingStore, type ValidationProblemDetails } from "./validating-store";
    import { ProblemDocument } from "http-problem-details";

	interface ContactRequest {
		message: string;
		fullname: string;
		email: string;
		phone: string;
	}

	const initialData: ContactRequest = {
		message: "",
		fullname: "",
		email: "",
		phone: ""
	};
	const validationFunc = (data: ContactRequest, fieldName?: string) => {
		only(fieldName);

		test("message", "Bitte geben Sie eine Nachricht ein", () => {
			enforce(data.message).isNotEmpty();
		});

		test("fullname", "Bitte geben Sie Ihren Namen ein", () => {
			enforce(data.fullname).isNotEmpty();
		});
		
		test("email", () => {
			enforce(data.email)
				.message("Bitte geben Sie Ihre E-Mail-Adresse ein").isNotBlank()
				.message("Bitte geben Sie eine gültige E-Mail-Adresse ein").isEmail();
		});
	};

	let token: string = "";
    let isOpen: boolean = false;
	let errorMessage: string = "";
	let state: FormState = FormState.Ready;
	let store = new ValidatingStore(initialData, validationFunc);

	onMount(() => {
		let tokenElement = document.getElementById("RequestVerificationToken");
		if (tokenElement == null || tokenElement.textContent == null) {
			throw new Error("Could not load XSRF token.");
		}
		token = JSON.parse(tokenElement.textContent);

		document.addEventListener("openContactForm", () => {
			isOpen = true;
		});
	});
	
	function handleKeydown(event: KeyboardEvent) {
		if (event.key === "Escape" && isOpen) {
			close();
		}
	}

	async function submit() {
		try {
			if (!store.validate()) {
				return;
			}

			state = FormState.Loading;

			let response = await ky.post(`/api/contact`, {
				headers: { RequestVerificationToken: token },
				json: store.getData(),
				throwHttpErrors: false,
			});

			if (response.ok) {
				state = FormState.Success;
				trackEvent("Kontaktformular abgeschickt");
			} else if (response.status === 400) {
				state = FormState.Ready;
				let problem = await response.json<ValidationProblemDetails>();
				store.setExternalErrors(problem);
			} else {
				let problem = await response.json<ProblemDocument>();
				errorMessage = problem.detail ?? problem.title;
				state = FormState.Error;
			}
		} catch (e) {
			state = FormState.Error;
		}
	}

    function close() {
		store.clear();
        isOpen = false;
		state = FormState.Ready;
    }
</script>

<svelte:window on:keydown={handleKeydown} />

<div id="contactModal" class="contact-form modal modal-fx-slideTop" class:is-active={isOpen}>
	<div class="modal-background"></div>
	<div class="modal-content">
		<div class="card">
			<div class="card-content">
				<div class="field">
					<label class="label" for="Message">Wie können wir Ihnen weiterhelfen?</label>
					<p class="control">
						<textarea id="Message" name="Message" class="textarea" placeholder="Ich interessiere mich für …" bind:value={$store.message.value} on:blur={() => $store.message.touch()} class:is-danger={$store.message.hasErrors}></textarea>
					</p>
					{#each $store.message.errors as error}
						<p class="help is-danger">{error}</p>
					{/each}
				</div>

				<div class="field">
					<label class="label is-small" for="Fullname">Ihr Name</label>
					<p class="control">
						<input id="Fullname" name="Fullname" class="input" type="text" placeholder="Alex Muster" autocomplete="name" bind:value={$store.fullname.value} on:blur={() => $store.fullname.touch()} class:is-danger={$store.fullname.hasErrors} />
					</p>
					{#each $store.fullname.errors as error}
						<p class="help is-danger">{error}</p>
					{/each}
				</div>
				<div class="field">
					<label class="label is-small" for="Email">Ihre E-Mail-Adresse</label>
					<p class="control">
						<input id="Email" name="Email" class="input" type="email" placeholder="alexmuster@example.com" autocomplete="email" bind:value={$store.email.value} on:blur={() => $store.email.touch()} class:is-danger={$store.email.hasErrors} />
					</p>
					{#each $store.email.errors as error}
						<p class="help is-danger">{error}</p>
					{/each}
				</div>
				<div class="field">
					<label class="label is-small" for="Phone">Ihre Telefonnummer (optional)</label>
					<p class="control">
						<input id="Phone" name="Phone" class="input" type="tel" placeholder="012 345 67 89" autocomplete="tel" bind:value={$store.phone.value} on:blur={() => $store.phone.touch()} class:is-danger={$store.phone.hasErrors} />
					</p>
					{#each $store.phone.errors as error}
						<p class="help is-danger">{error}</p>
					{/each}
				</div>
				<div class="field is-grouped mt-4">
					<div class="control is-expanded">
						<button class="button is-primary" class:is-loading={state === FormState.Loading} on:click={submit}>Kontakt aufnehmen</button>
					</div>
					<div class="control">
						<button type="button" class="button is-text" on:click={close}>Schliessen</button>
					</div>
				</div>
			</div>
		</div>
	</div>
	<button class="modal-close is-large" aria-label="close" on:click={close}></button>
</div>

<div class="modal" class:is-active={state === FormState.Success}>
	<div class="modal-background"></div>
	<div class="modal-content">
		<div class="notification is-error">
			<h2 class="title is-4">Nachricht abgeschickt</h2>
			<p>Vielen Dank für Ihre Nachricht.</p>
			<button type="button" class="button is-text" on:click={close}>Schliessen</button>
		</div>
	</div>
	<button class="modal-close is-large" aria-label="close" on:click={close}></button>
</div>

<div class="modal" class:is-active={state === FormState.Error}>
	<div class="modal-background"></div>
	<div class="modal-content">
		<div class="notification is-error">
			<h2 class="title is-4">Fehler beim Senden</h2>
			<p>Beim Senden Ihrer Nachricht ist ein Fehler aufgetreten. Bitte kontaktieren Sie uns direkt unter <a href="mailto:info@sichermarti.ch">info@sichermarti.ch</a></p><p><small><em>{errorMessage}</small><p>
			<button type="button" class="button is-text" on:click={close}>Schliessen</button>
		</div>
	</div>
	<button class="modal-close is-large" aria-label="close" on:click={close}></button>
</div>
