<script lang="ts">
	import { onMount } from "svelte";
	import ky from "ky";
	import { create, enforce, test, group, skip, only } from "vest";
	import "vest/enforce/email";
	import Cookies from "js-cookie";
	
    import { AnalysisCtaCookieName, FormState } from "./utils";
	import { trackEvent } from "./analytics";
	import { ValidatingStore, type ValidationProblemDetails } from "./validating-store";
    import { ProblemDocument } from "http-problem-details";

	interface AnalysisRequest {
		company: string;
		fullname: string;
		streetAddress: string;
		postalCode: string;
		locality: string;
		email: string;
		phone: string;
		message: string;
	}

	const initialData: AnalysisRequest = {
		company: "",
		fullname: "",
		streetAddress: "",
		postalCode: "",
		locality: "",
		email: "",
		phone: "",
		message: ""
	};
	const validationFunc = (data: AnalysisRequest, fieldName?: string) => {
		only(fieldName);

		test("message", "Bitte geben Sie eine Nachricht ein", () => {
			enforce(data.message).isNotEmpty();
		});

		test("fullname", "Bitte geben Sie Ihren Namen ein", () => {
			enforce(data.fullname).isNotEmpty();
		});
		
		test("email", () => {
			enforce(data.email)
				.message("Bitte geben Sie Ihre E-Mail-Adresse ein").isNotBlank()
				.message("Bitte geben Sie eine gültige E-Mail-Adresse ein").isEmail();
		});
	};

	let token: string = "";
    let isOpen: boolean = false;
	let errorMessage: string = "";
	let state: FormState = FormState.Ready;
	let store = new ValidatingStore(initialData, validationFunc);

	onMount(() => {
		let tokenElement = document.getElementById("RequestVerificationToken");
		if (tokenElement == null || tokenElement.textContent == null) {
			throw new Error("Could not load XSRF token.");
		}
		token = JSON.parse(tokenElement.textContent);

		document.addEventListener("openAnalysisForm", () => open());
	});
	
	function handleKeydown(event: KeyboardEvent) {
		if (event.key === "Escape" && isOpen) {
			close();
		}
	}

	async function submit() {
		try {
			if (!store.validate()) {
				return;
			}

			state = FormState.Loading;

			let response = await ky.post(`/api/analysis`, {
				headers: { RequestVerificationToken: token },
				json: store.getData(),
				throwHttpErrors: false,
			});

			if (response.ok) {
				state = FormState.Success;
				trackEvent("Sicherheitscheck gebucht");
				Cookies.set(AnalysisCtaCookieName, "true", { expires: 720 });
			} else if (response.status === 400) {
				state = FormState.Ready;
				let problem = await response.json<ValidationProblemDetails>();
				store.setExternalErrors(problem);
			} else {
				let problem = await response.json<ProblemDocument>();
				errorMessage = problem.detail ?? problem.title;
				state = FormState.Error;
			}
		} catch (e) {
			state = FormState.Error;
		}
	}

	function open() {
		isOpen = true;
		Cookies.set(AnalysisCtaCookieName, "true", { expires: 14 });
	}

    function close() {
        isOpen = false;
		state = FormState.Ready;
		store.clear();
    }
</script>

<svelte:window on:keydown={handleKeydown} />

<div id="analysisModal" class="contact-form modal modal-fx-slideTop" class:is-active={isOpen}>
	<div class="modal-background"></div>
	<div class="modal-content">
		<div class="card">
			<div class="card-content">
				<div class="mb-3">
					<div class="title is-3 mb-0">Gratis Sicherheitscheck bestellen</div>
					<p>Bei Ihnen Zuhause oder in der Firma. </p>
				</div>
				<div class="field">
					<label class="label is-small" for="analysisModalCompany">
						Firma
						<span class="help is-inline">(optional)</span>
					</label>
					<div class="control">
						<input id="analysisModalCompany" name="Company" class="input" type="text" placeholder="Acme AG" autocomplete="organization" bind:value={$store.company.value} on:blur={() => $store.company.touch()} class:is-danger={$store.company.hasErrors} />
					</div>
					{#each $store.company.errors as error}
						<p class="help is-danger">{error}</p>
					{/each}
				</div>
				<div class="field">
					<label class="label is-small" for="analysisModalFullname">Ihr Name</label>
					<div class="control">
						<input id="analysisModalFullname" name="Fullname" class="input" type="text" placeholder="Alex Muster" autocomplete="name" bind:value={$store.fullname.value} on:blur={() => $store.fullname.touch()} class:is-danger={$store.fullname.hasErrors} />
					</div>
					{#each $store.fullname.errors as error}
						<p class="help is-danger">{error}</p>
					{/each}
				</div>
				<div class="field">
					<label class="label is-small" for="analysisModalStreetAddress">Strasse und Nr.</label>
					<div class="control">
						<input id="analysisModalStreetAddress" name="StreetAddress" class="input" type="text" placeholder="Musterstrasse 1" autocomplete="address-line1" bind:value={$store.streetAddress.value} on:blur={() => $store.streetAddress.touch()} class:is-danger={$store.streetAddress.hasErrors} />
					</div>
					{#each $store.streetAddress.errors as error}
						<p class="help is-danger">{error}</p>
					{/each}
				</div>
				<div class="field is-horizontal">
					<div class="field-body">
						<div class="field is-narrow">
							<label class="label is-small" for="analysisModalPostalCode">PLZ</label>
							<div class="control">
								<input id="analysisModalPostalCode" name="PostalCode" class="input" type="text" placeholder="1234" autocomplete="postal-code" bind:value={$store.postalCode.value} on:blur={() => $store.postalCode.touch()} class:is-danger={$store.postalCode.hasErrors} />
							</div>
							{#each $store.postalCode.errors as error}
								<p class="help is-danger">{error}</p>
							{/each}
						</div>
						<div class="field">
							<label class="label is-small" for="analysisModalLocality">Ort</label>
							<div class="control">
								<input id="analysisModalLocality" name="Locality" class="input" type="text" placeholder="Musterort" autocomplete="address-level2" bind:value={$store.locality.value} on:blur={() => $store.locality.touch()} class:is-danger={$store.locality.hasErrors} />
							</div>
							{#each $store.locality.errors as error}
								<p class="help is-danger">{error}</p>
							{/each}
						</div>
					</div>
				</div>
				<div class="field is-horizontal">
					<div class="field-body">
						<div class="field">
							<label class="label is-small" for="analysisModalEmail">Ihre E-Mail-Adresse</label>
							<div class="control">
								<input id="analysisModalEmail" name="Email" class="input" type="email" placeholder="alexmuster@example.com" autocomplete="email" bind:value={$store.email.value} on:blur={() => $store.email.touch()} class:is-danger={$store.email.hasErrors} />
							</div>
							{#each $store.email.errors as error}
								<p class="help is-danger">{error}</p>
							{/each}
						</div>
						<div class="field">
							<label class="label is-small" for="analysisModalPhone">Ihre Telefonnummer (optional)</label>
							<div class="control">
								<input id="analysisModalPhone" name="Phone" class="input" type="tel" placeholder="012 345 67 89" autocomplete="tel" bind:value={$store.phone.value} on:blur={() => $store.phone.touch()} class:is-danger={$store.phone.hasErrors} />
							</div>
							{#each $store.phone.errors as error}
								<p class="help is-danger">{error}</p>
							{/each}
						</div>
					</div>
				</div>

				<div class="field">
					<label class="label is-small" for="analysisModalMessage">Möchten Sie uns vorgängig schon etwas mitteilen?</label>
					<div class="control">
						<textarea id="analysisModalMessage" name="Message" class="textarea" placeholder="Ich interessiere mich für …" bind:value={$store.message.value} on:blur={() => $store.message.touch()} class:is-danger={$store.message.hasErrors}></textarea>
					</div>
					{#each $store.message.errors as error}
						<p class="help is-danger">{error}</p>
					{/each}
				</div>

				<div class="field is-grouped mt-4">
					<div class="control is-expanded">
						<button class="button is-primary" class:is-loading={state === FormState.Loading} on:click={submit}>Gratis-Check bestellen</button>
					</div>
					<div class="control">
						<button type="button" class="button is-text" on:click={close}>Schliessen</button>
					</div>
				</div>
			</div>
		</div>
	</div>
	<button class="modal-close is-large" aria-label="close" on:click={close}></button>
</div>

<div class="modal" class:is-active={state === FormState.Success}>
	<div class="modal-background"></div>
	<div class="modal-content">
		<div class="notification is-error">
			<h2 class="title is-4">Anfrage abgeschickt</h2>
			<p>Vielen Dank für Ihr Interesse an unserem Sicherheitscheck. Wir werden uns so bald wie möglich bei Ihnen melden.</p>
			<button type="button" class="button is-text" on:click={close}>Schliessen</button>
		</div>
	</div>
	<button class="modal-close is-large" aria-label="close" on:click={close}></button>
</div>

<div class="modal" class:is-active={state === FormState.Error}>
	<div class="modal-background"></div>
	<div class="modal-content">
		<div class="notification is-error">
			<h2 class="title is-4">Fehler beim Senden</h2>
			<p>Beim Senden Ihrer Anfrage ist ein Fehler aufgetreten. Bitte kontaktieren Sie uns direkt unter <a href="mailto:info@sichermarti.ch">info@sichermarti.ch</a></p><p><small><em>{errorMessage}</small><p>
			<button type="button" class="button is-text" on:click={close}>Schliessen</button>
		</div>
	</div>
	<button class="modal-close is-large" aria-label="close" on:click={close}></button>
</div>
