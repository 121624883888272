<script lang="ts">
    import { onMount } from "svelte";
	import Cookies from "js-cookie";
    import { AnalysisCtaCookieName } from "./utils";

	let animationClasses: string[] = [];
	$: messageClasses = ["message", "is-primary", "has-background-grey-lighter", "analysis-cta-message", "is-offscreen", "animate__animated", ...animationClasses].join(" ");

	onMount(() => {
		if (Cookies.get(AnalysisCtaCookieName) != "true") {
			window.setTimeout(() => {
				animationClasses = ["animate__bounceInUp"];
			}, 1000);
		}
	});

	function dismiss() {
		animationClasses = ["animate__backOutLeft"];
		Cookies.set(AnalysisCtaCookieName, "true", { expires: 90 });
	}

	function openAnalysisForm() {
		document.dispatchEvent(new CustomEvent("openAnalysisForm"));
	}
</script>

<div id="analysisCtaMessage" class={messageClasses}>
	<div class="message-body has-text-black-ter">
		<button class="delete" aria-label="delete" on:click={dismiss}></button>
		<h2 class="title is-size-4 is-uppercase has-text-weight-bold mb-0">Gratis Sicherheitscheck</h2>
		<p>Bei Ihnen Zuhause oder in der Firma. </p>
		<button class="button is-primary is-medium" on:click={openAnalysisForm}>Gratis-Check bestellen</button>
	</div>
</div>
